export default class EventEmitter {
    static CH_SET_LANG = "ch-set-lang";

    static emitSetLang(newLang) {
        EventEmitter.emitBodyDispatchEvent(EventEmitter.CH_SET_LANG, {newLang});
    }

    static CH_ADMIN_SECTIONS = "ch-admin-sections";

    static emitAdminSections(action) {
        EventEmitter.emitBodyDispatchEvent(EventEmitter.CH_ADMIN_SECTIONS, {action});
    }

    static CH_SET_ADMIN = "ch-set-admin";

    static emitAdminAction(action) {
        EventEmitter.emitBodyDispatchEvent(EventEmitter.CH_SET_ADMIN, {action});
    }

    static emitAdminMenu(newSection) {
        EventEmitter.emitBodyDispatchEvent(EventEmitter.CH_SET_ADMIN, {newSection});
    }

    // listing switch to admin view / not admin view
    static CH_TOGGLE_ADMIN = "ch-toggle-admin";

    static emitToggleAdmin() {
        EventEmitter.emitBodyDispatchEvent(EventEmitter.CH_TOGGLE_ADMIN);
    }

    static CH_SLIDE_IMAGE = "ch-slide-image";

    static emitSlideImage(imageId) {
        EventEmitter.emitBodyDispatchEvent(EventEmitter.CH_SLIDE_IMAGE, {imageId});
    }

    //~ common

    static emitBodyDispatchEvent(eventName, detail) {
        if (!window.isSet(detail)) {
            document.body.dispatchEvent(new CustomEvent(eventName));
            return;
        }
        document.body.dispatchEvent(new CustomEvent(eventName,{detail}));
    }
}
