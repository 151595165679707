import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import { Form } from 'react-bootstrap';
import { CConstants } from '../common/CConstants';
import CUtils from '../services/CUtils';
import { analyticsEvent } from '../core/AnalyticsManager';
import ApiV0 from '../services/ApiV0';

import './CCodePromo.css';
import { withTranslation } from 'react-i18next';

class CCodePromo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      code: "",
      step: "start"
    }
  }

  onUpdated(msg) {
    if (this.props.onUpdated === undefined) {
      console.error("CCodePromo: onUpdated not defined");
      return;
    }
    // DEBUG // console.info(`CCodePromo: onUpdated : ${msg}`);
    this.props.onUpdated(msg);
  }

  onAdd() {
    this.setState({step: 'add', errorMessage: null}, () => this.addFocus());
  }

  addFocus() {
    this.codeInput.focus()
  }

  onCancelCodeValue() {
    this.setState({step: 'start'});
  }

  onAddCodeValue() {
    const { t } = this.props;
    const displayName = this.props?.meProfile?.displayName || "guest";
    ApiV0.addPromotionCode({code:this.state.code})
    .then((success) => {
        const evt = `add promotion code`;
        analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, displayName);
        this.setState({step: 'start', code: '', errorMessage: null}, () => this.onUpdated(t('promo.codeAdded')));
    })
    .catch(err => {
        if ("NOT_FOUND" === err.code) {// loose session
          this.setState({errorMessage: t('common.sessionLost')}, () => document.location.href ="/login");
          return;
        }
        console.info("add promotion code error: "+ err);
        const evt = `add promotion code error`;
        analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, displayName);
        const errorMessage = "INVALID_PARAMETER" === err.code ? err.details : CUtils.userErrorOf("add code", t, err);
        this.setState({errorMessage});
    });
  }

  renderAddButton(cb, cbCancel) {
    const { t } = this.props;
    const addDisabled = this.state.code === undefined || this.state.code === '';
    return (<>
       <Button variant="light" size="sm" style={{ margin: 5, padding: 5, whiteSpace: 'nowrap' }}
                    onClick={cb} title={t('promo.addCode')}
                    disabled={addDisabled}>{t('promo.apply')}</Button>
    </>);
  }

  renderStart() {
    const { t } = this.props;
    return (<div className="alignRight"
                 onClick={this.onAdd.bind(this)}
                 style={{cursor: 'pointer', textDecoration: 'underline'}}>{t('promo.iHadOne')}</div>);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    var name = target.name;
    this.setState({
      [name]: value
    });
  }

  renderAdd() {
    const { t } = this.props;
    const { code, errorMessage } = this.state;
    const formAdd = (
       <Form>
       <div className="floatRight addCodeRow">
         <div className="formRowEntry">{t('promo.form.code.label')}:</div>
         <div className="formRowEntry">
              <Form.Control
                    name="code"
                    type="text"
                    value={code}
                    onChange={this.handleInputChange.bind(this)}
                    autoComplete="new-password"
                    className="form-control"
                    placeholder={t('promo.form.code.placeholder')}
                    pattern={CConstants.CODE_REGEX}
                    maxLength={CConstants.CODE_MAX_LENGTH}
                    ref={(input) => { this.codeInput = input; }}
                    style={{ width: '150px'}}
                    />
         </div>
         <div className="formRowEntry">
              {this.renderAddButton(this.onAddCodeValue.bind(this), this.onCancelCodeValue.bind(this))}
         </div>
       </div>
       <div className="clearBoth"/>
       {errorMessage !== null ? (<i>{errorMessage}</i>):null}
      </Form>
    );
    return (<div className="alignRight">{formAdd}</div>);
  }

  render() {
    return this.state.step === 'start' ? this.renderStart() :
           this.state.step === 'add' ? this.renderAdd() :
           null;
  }
}

export default withTranslation('common')(CCodePromo);